<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Tipi Confezione"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-2" style="width: 100%">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :show-filters="false"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Nome: {{ filter.value }}
          </template>

        </AdvancedFilter>
      </div>
      <div 
        :style="{
          'height': $vuetify.breakpoint.smAndDown ? '81%' : '86%', 
          'overflow-y': 'hidden'
        }" 
        ref="dataTableWrapper"
      >
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento Tipi Confezione ..."
          :headers="headers"
          :loading="loading"
          :items="filteredPackageTypes"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          @edit="handleEdit"
          @delete="handleDelete"
          :show-select="false"
          @dblclick:row="handleEditDoubleClick"
        ></TypeDataTable>
      </div>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import packageTypesService from '@/services/packageTypes/packageTypes.service.js'

export default {
  name: "PackageTypesRegistryList",
  components: {
    FullScreenDialog,
    AdvancedFilter,
    TypeDataTable,
    StandardMenu
  },
  data: function() {
    let props = this.$router.resolve({name: 'Warehouse'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      packageTypes: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      resizeListener: undefined,
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [],
      isLicenseBlocked: undefined
    }
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchPackageTypes()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchPackageTypes() {
      let filters = [...this.filtersValue]
      this.loadingItems = true
      packageTypesService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.packageTypes = results.rows
        this.loadingItems = false
      })
    },
    applyFilters() {
      this.fetchPackageTypes();
    },
    fetchHeaders() {
      this.loadingHeaders = true
      packageTypesService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(packageType) {
      this.$router.push({
        name: 'PackageTypesRegistryEditForm',
        params: {
          id: packageType.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'PackageTypesRegistryList'}).href,
          pathName: "PackageTypesRegistryList"
        }
      })
    },
    handleEditDoubleClick(row, {item: packageType}) {
      this.$router.push({
        name: 'PackageTypesRegistryEditForm',
        params: {
          id: packageType.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'PackageTypesRegistryList'}).href,
          pathName: "PackageTypesRegistryList"
        }
      })
    },
    handleDelete(packageType) {
      let confirmed = confirm('Sei sicuro di voler eliminare ' + packageType.name + ' ?')

      if(confirmed) {
        this.loading
        packageTypesService.delete(packageType).then(() => {
          this.fetchPackageTypes()
          this.loading
        })
      }
    },
    goToNew() {
      this.$router.push({
        name: 'PackageTypesRegistryNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'PackageTypesRegistryList'}).href
        }
      })
    }
  },
  computed: {
    filteredPackageTypes() {
      return this.packageTypes
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchPackageTypes()
    },
    rowPerPage() {
      this.fetchPackageTypes()
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>